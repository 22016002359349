(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/rectangular-image/views/rectangular-image.js') >= 0) return;  svs.modules.push('/components/banner_layouts/rectangular-image/views/rectangular-image.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.rectangular_image=_svs.banner_layouts.rectangular_image||{};
_svs.banner_layouts.rectangular_image.templates=_svs.banner_layouts.rectangular_image.templates||{};
svs.banner_layouts.rectangular_image.templates.rectangular_image = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "    data-impression=\"true\"\n    data-impression-tracking=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"trackingString") || (depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"trackingString","hash":{},"data":data,"loc":{"start":{"line":13,"column":30},"end":{"line":13,"column":48}}}) : helper)))
    + "\"\n  >\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"card",{"name":"ui","hash":{"branding":"sport"},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":48,"column":9}}})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      data-cmsid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"bannerId") || (depth0 != null ? lookupProperty(depth0,"bannerId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bannerId","hash":{},"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":30}}}) : helper)))
    + "\"\n      data-cmsType=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":35}}}) : helper)))
    + "\"\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div style='height: "
    + ((stack1 = (lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"bannerHeight") : depth0),"px",{"name":"concat","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":26},"end":{"line":16,"column":66}}})) != null ? stack1 : "")
    + "'>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),{"name":"with","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasBgImage") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":6},"end":{"line":22,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"card-img-overlay fc-white\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"card/card-body",{"name":"ui","hash":{"class":"padding-xs-0 h100"},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":6},"end":{"line":45,"column":13}}})) != null ? stack1 : "")
    + "      </div>\n      \n";
},"5":function(container,depth0,helpers,partials,data) {
    return "";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),depth0,{"name":"components-content-image_v2-image","hash":{"fitWrapper":"true"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class='banner-rectangular-image-no-image' style='height: "
    + ((stack1 = (lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"bannerHeight") : depth0),"px",{"name":"concat","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":70},"end":{"line":21,"column":110}}})) != null ? stack1 : "")
    + "'/>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"u-flex u-flex-column u-flex-justify-content-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkText") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":26,"column":64},"end":{"line":26,"column":106}}})) != null ? stack1 : "")
    + " h100 f-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkText") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":10},"end":{"line":36,"column":17}}})) != null ? stack1 : "")
    + "          <h2 class=\"h4 margin-xs-bottom-0 padding-xs-1 u-text-left banner-rectangular-image-gradient\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"heading") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":42,"column":19}}})) != null ? stack1 : "")
    + "          </h2>\n        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "between";
},"14":function(container,depth0,helpers,partials,data) {
    return "end";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"u-flex u-flex-justify-content-end w100\">\n              <div class=\"u-flex u-text-right fc-black\">\n                <div\n                  class=\"u-position-relative u-flex u-flex-align-items-center padding-xs-right-1 bg-white f-200 f-bold f-uppercase banner-rectangular-image-link\">\n                  "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"linkText") || (depth0 != null ? lookupProperty(depth0,"linkText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"linkText","hash":{},"data":data,"loc":{"start":{"line":32,"column":18},"end":{"line":32,"column":30}}}) : helper)))
    + "\n                </div>\n              </div>\n            </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"heading") || (depth0 != null ? lookupProperty(depth0,"heading") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"heading","hash":{},"data":data,"loc":{"start":{"line":39,"column":14},"end":{"line":39,"column":25}}}) : helper)))
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"placeholderHeading") || (depth0 != null ? lookupProperty(depth0,"placeholderHeading") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"placeholderHeading","hash":{},"data":data,"loc":{"start":{"line":41,"column":14},"end":{"line":41,"column":36}}}) : helper)))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-banner_actions-link-link"),depth0,{"name":"components-banner_actions-link-link","hash":{"crm":(depth0 != null ? lookupProperty(depth0,"crm") : depth0),"clickTrackingString":(depth0 != null ? lookupProperty(depth0,"trackingString") : depth0),"action":(depth0 != null ? lookupProperty(depth0,"action") : depth0)},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-banner_layouts-rectangular_image-rectangular_image'] = svs.banner_layouts.rectangular_image.templates.rectangular_image;
})(svs, Handlebars);


 })(window);