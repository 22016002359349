(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/rectangular-image/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_layouts/rectangular-image/assets/javascripts/render-component.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isServer = typeof exports === 'object';

async function renderComponent(model, widgetInfo, position, req, res) {
  let hbsCompiled;
  let format;
  let isAdmin;
  let features;
  if (isServer) {
    const trinidad = require('trinidad-core').core;
    const hbs = global.internalInstances.get('hbs');
    hbsCompiled = hbs.cache['components-banner_layouts-rectangular_image-rectangular_image'];
    format = trinidad.components.require('utils', 'format').api;
    isAdmin = req.userSession.hasRole(req.userSession.roles.INTERNAL);
    features = res.locals.features;
  } else {
    var _svs;
    hbsCompiled = svs.banner_layouts.rectangular_image.templates.rectangular_image;
    format = svs.utils.format;
    isAdmin = svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL);
    features = (_svs = svs) === null || _svs === void 0 || (_svs = _svs.core) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.features;
  }
  const {
    callToActions,
    contentType: modelContentType,
    id,
    images,
    layoutConfig,
    layoutType,
    title,
    targetOffer
  } = model || {};
  const action = callToActions[0].actionConfig;
  const bannerHeight = 110;
  const hasBgImage = images && Boolean(images[0]);
  const image = hasBgImage && _objectSpread(_objectSpread({}, images[0]), {}, {
    aspectRatio: {
      width: '100%',
      height: '110'
    }
  });
  const contentType = (modelContentType === null || modelContentType === void 0 ? void 0 : modelContentType.split(':').pop()) || 'banner';
  const crm = targetOffer || '';
  const placeholderHeading = 'Exempelrubrik';
  const productOrLinkText = Boolean(layoutConfig.product) || Boolean(layoutConfig.linkText);
  const trackingStringTitle = format.safeString(title) || title;
  const trackingString = [widgetInfo.widgetType, widgetInfo.trackingId, position, layoutType, trackingStringTitle].join('/');
  return hbsCompiled(_objectSpread(_objectSpread({}, layoutConfig), {}, {
    action,
    bannerId: id,
    bannerHeight,
    image,
    contentType,
    crm,
    features,
    hasBgImage,
    isAdmin,
    placeholderHeading,
    productOrLinkText,
    trackingString
  })) || '';
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  svs.banner_layouts.rectangular_image = {
    renderComponent
  };
}

 })(window);